<template>
  <div class="st-container" v-if="isLanding">
    <LandingBanner :dealTypeCode="dealTypeCode"/>
    <!-- <LandingDeal v-if="landingDealData.length>0" v-model="dealTypeCode"/> -->
    <Lesiurtabs
      :dealData="dealData"
      :dealType="dealType"
      :banner="middleBanner"
      :bannerHref="middleBannerHref"
      v-if="dealData.length > 0" />
    <ContentLoading v-else type="deal" />
  </div>
  <div class="st-container" v-else-if="isLandingChain">
    <LandingChainBanner :chainName="chainName"/>
    <!-- <LandingDeal v-if="landingDealData.length>0" v-model="dealTypeCode"/> -->
    <Lesiurtabs
    :dealData="dealChainData"
    :dealType="dealType"
    :banner="middleBanner"
    :bannerHref="middleBannerHref"
    v-if="dealChainData.length > 0" />
    <ContentLoading v-else type="deal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import getBannerImage from '@/mixins/getBannerImage';

export default {
  name: 'LandingContent',
  mixins: [getBannerImage],
  computed: {
    ...mapGetters({
      dealData: 'GET_LANDING_DEAL_DATA',
      dealType: 'GET_DEAL_TYPE',
      isLanding: 'GET_IS_LANDING_PAGE',
      isLandingChain: 'GET_IS_LANDING_PAGE_CHAIN',
      dealChainData: 'GET_LANDING_DEAL_CHAIN_DATA',
    }),
    dealTypeCode() {
      const { query } = this.$route;
      return query.destination || '';
    },
    chainName() {
      const { query } = this.$route;
      return query.chain || '';
    },
  },
  components: {
    LandingBanner: () => import('@/components/landing/LandingBanner'),
    // LandingDeal: () => import('@/components/landing/LandingDeal'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    Lesiurtabs: () => import('@/components/home/Lesiurtabs'),
    LandingChainBanner: () => import('@/components/landing/LandingChainBanner'),
  },
};
</script>

<style>
@media (min-width: 1200px){
  .container {
    max-width: 1500px;
  }
}
</style>
